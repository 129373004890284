import React, { useEffect, useRef } from "react";
import Table from "../../shared/tables/material_table";
import {
  Badge,
  FormControl,
  FormControlLabel,
  Radio,
  Select,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import pureIcons from "../../shared/pure_icons";
import { MenuItem } from "react-pro-sidebar";
import {
  getFilterSelection,
  saveFilterSelection,
} from "../../shared/filter_selection";
import ProcessFrom from "./_process_from";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      order_number: "",
      orderStateValue: false,
      selectedRoles: "",
    };
    this._tableRef = React.createRef();
    this._processRef = React.createRef();
    this.selectedValue = React.createRef("All");
    this.selectedValue.current =
      getFilterSelection("hub-selected-filter") || props.initialValue || "All";
    this.shippingOptions = React.createRef();
  }

  reloadData = (fullReload = false) => {
    if (fullReload) this.setState({ muiTableKey: this.state.muiTableKey + 1 });
    this._tableRef.current.onQueryChange();
  };

  handleRoleFilterChange = (val) => {
    this.setState({ setSelectedRoles: val });
    this.selectedValue.current = val;
    this.reloadData();
  };

  render() {
    const columns = [
      {title: "Tracking", field: "tracking", sorting: false, cellStyle: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: 400}, filtering: false},
      {title: '', field: 'raw_tracking', hidden: true},
      {title: "Order Number", field: "order_number", sorting: false, filtering: false, editable: "never", render: (rowData) => (
          <Badge
            badgeContent={rowData.new_customer ? "new" : null}
            color="secondary"
          >
            {rowData.order_number}
          </Badge>
        ),
      },
      {title: "Invoice", field: "invoice", filtering: false, sorting: false, editable: "never", cellStyle: {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: 350, filtering: false}},
      {title: "Vendor", field: "vendor", filtering: false, editable: "never", sorting: false},
      {title: "Shipping Method", field: "shipping_option", sorting: false, editable: "never", cellStyle: { whiteSpace: "normal", wordWrap: "break-word", width: "13%"},
        filterComponent: () => (
          <FormControl component="fieldset" style={{ width: "90%" }}>
            <Select
              value={this.state.selectedRoles || this.selectedValue.current}
              onChange={(e) => {
                this.handleRoleFilterChange(e.target.value),
                  (this.selectedValue.current = e.target.value);
                saveFilterSelection("hub-selected-filter", e.target.value);
              }}
            >
              {this.shippingOptions.current?.map((item) => {
                return (
                  <MenuItem value={item} style={{ padding: "0rem 1rem" }}>
                    <FormControlLabel
                      value={item}
                      control={
                        <Radio checked={this.selectedValue.current == item} />
                      }
                      label={item}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ),
        render: (rowData) => <span>{rowData?.shipping_option}</span>,
      },
      {title: "Date", field: "created_at", filtering: false, editable: "never"},
      {
        title: "Filter",
        field: "group_id",
        sorting: false,
        lookup: {
          ISSUE: "EXPIRED",
          DELIVERED: "DAMAGED",
          RETURN: "RETURN",
          EXPENSIVE: "WRONG ITEM",
          ___: "---",
        },
        emptyValue: "--",
      },
      { title: "source", field: "source", hidden: true },
      { title: "market_source", field: "market_source", hidden: true },
    ];

    return (
      <div className="bg-white rounded">
        <Backdrop open={this.state.working} />
        <ProcessFrom
          ref={this._processRef}
          reloadData={this.reloadData}
          orderState={this.selectedValue.current}
          shippingMethod={this.selectedValue.current}
        />
        <Table
          title={"Package Issues"}
          key={this.state.muiTableKey}
          options={{
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            searchAutoFocus: true,
            filtering: true,
          }}
          columns={columns}
          tableRef={this._tableRef}
          actions={[
            {
              icon: pureIcons.FiRefreshCw,
              onClick: () => {
                this.reloadData();
              },
              isFreeAction: true,
              tooltip: "Refresh",
            },
            {
              icon: () => <pureIcons.GiBoxUnpacking />,
              tooltip: <span>Process</span>,
              onClick: (event, rowData) => this._processRef.current.handleOpen(rowData),
              position: "row",
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var filters;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }
              const source = localStorage.getItem("source");
              const destination = localStorage.getItem("destination");
              let url = `/order_item_stages/exceptions.json??source=${source}&location=${destination}&shipping_option=${this.selectedValue.current}`;
              url += "&per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.filters.length > 0) {
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => {
                  if (!response.ok) {
                    if (response.status === 401) {
                      throw new Error(
                        "You need to sign in or sign up before continuing."
                      );
                    }
                  }
                  return response.json();
                })
                .then((result) => {
                  this.shippingOptions.current = result.shipping_methods;
                  resolve({
                    data: result.orders,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
                })
                .catch((error) => {
                  if (
                    error.message ===
                    "You need to sign in or sign up before continuing."
                  ) {
                    window.location.href = "/";
                  } else {
                    console.error("Error:", error);
                  }
                });
            })
          }
        />
      </div>
    );
  }
}
export default withSnackbar(Index);
