import React from 'react';
import Modal from '../shared/modal/material_dialog';
import NoteForm from "../notes/_note_form";
import Tab from './_customized_tabs'
import { Badge, Button } from '@material-ui/core';

export default class ShowOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			order_number: '',
			notes_count: 0,
			maxWidth: 'sm',
			new_customer: false,
			order: { vendors: [] }
		};
	}

	handleOpen = (order_number, source) => {
		this.setState({ open: true, order_number: order_number, maxWidth: order_number ? 'xl' : 'sm', source: source });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	reset = () => {
		this.setState({ order_number: null, maxWidth: 'sm' })
	}

	setNewCustomer = (value) => {
		this.setState({ new_customer: value });
	}

	handleSelectChange = object => {
		this.setState({ order_number: object.value[1], maxWidth: 'xl' })
	}

	render() {
		const body = <Tab order_number={this.state.order_number} source={this.state.source} callBack={this.setNewCustomer} />

		const orderTitle = <>
			{this.state.order_number}
			<NoteForm key={this.state.order_number} notes_count={this.state.order.notes_count} source={this.state.source} newNoteChild={() => this.setState({ notes_count: this.state.notes_count + 1 })} />
		</>
		
		return (
			<Modal
				disableBackdropClick={false}
				isOpen={this.state.open}
				title={this.state.order_number && orderTitle}
				handleClose={this.handleClose}
				minHeight='30%'
				maxWidth={this.state.maxWidth}
				maxHeight='85%'
			>
				{body}
			</Modal>
		);
	}
}
