import React from "react";
import Table from "../../shared/tables/new_core_material_table";
import Backdrop from "../../shared/backdrop";
import NoteForm from "../../notes/_note_form";
import ShowItem from "../../order_items/show";
import StockForm from "../stocked/_stock_form";
import CONFIG from "./tableConfig";
import { getFilterSelection } from "../../shared/filter_selection";
import { fetchData } from "./api";
import { withSnackbar } from "notistack";
import AddToList from "./_add_to_packing_list_form";
import CreateShipment from "./_create_shipment";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      submitting: false,
      orderState: true,
      shippingMethods: [],
      hubOperators: []
    };
    this.shippingOption = React.createRef("All");
    this.shippingOption.current = getFilterSelection("hub-selected-filter") || props.initialValue || "All";
    this._tableRef = React.createRef();
    this._stockFormRef = React.createRef();
    this._noteFormRef = React.createRef();
    this._showItemRef = React.createRef();
    this._AddToListRef = React.createRef();
    this._CreateShipmentRef = React.createRef();
  }

  reloadData = () => {
    this._tableRef.current.onQueryChange();
  };

  handleRoleFilterChange = (val) => {
    this.setState({ setSelectedRoles: val });
    this.shippingOption.current = val;
    this.reloadData();
  };

  render() {
    const {classes} = this.props;
    const source = localStorage.getItem("source");
    const destination = localStorage.getItem("destination");

    return (
      <div className="bg-white rounded">
        <Backdrop open={this.state.submitting}/>
        <NoteForm ref={this._noteFormRef} newNoteChild={this.reloadData}/>
        <ShowItem ref={this._showItemRef} />
        <StockForm
          ref={this._stockFormRef}
          source={source}
          destination={destination}
          callBackStageQty={this.reloadData}
          stock={true}
          enqueueSnackbar={withSnackbar}
          classes={classes}
        />
        <AddToList
          ref={this._AddToListRef}
          callBackAddToList={this.reloadData}
          orderState={this.state.orderState}
          shipping_method={this.shippingOption.current}
        />
        <CreateShipment
          ref={this._CreateShipmentRef}
          callBackCreateShipment={this.reloadData}
          units={this.state.units}
          shipping_method={this.shippingOption.current}
        />
        <Table
          title="Current Packing List"
          options={{
            toolbar: true,
            paging: false,
            selection: true,
            debounceInterval: 1000,
          }}
          columns={CONFIG.TABLE_COLUMNS(this)}
          tableRef={this._tableRef}
          actions={CONFIG.TABLE_ACTIONS(this, source)}
          data={(query) => fetchData(query, source, destination, this)}
        />
      </div>
    );
  }
}

export default Index;
