import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  IconButton,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  Table,
  TableHead,
  Typography,
  Select,
  Box,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Divider,
  Button,
  FormHelperText
} from "@material-ui/core";
import Modal from "./modal/material_dialog";

const SourceDestinationSelection = forwardRef(({}: any, ref: any) => {
  const source = [
    { id: "global", name: "GLOBAL" },
    { id: "us", name: "US" },
    { id: "tr", name: "TR" },
    { id: "ae", name: "AE" },
    { id: "sa", name: "SA" },
    { id: "iq", name: "IQ" },
    { id: "qa", name: "QA" },
    { id: "cn", name: "CN" },
  ];

  const destination = [
    { id: "iq", name: "IQ" },
    { id: "sa", name: "SA" },
    { id: "ae", name: "AE" },
    { id: "ng", name: "NG" },
  ];

  const [selectedSource, setSelectedSource] = useState<any>(localStorage.getItem("destination") || "");
  const [selectedDestination, setSelectedDestination] = useState<any>(localStorage.getItem("source") || "");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    source: false,
    destination: false
  });

  useEffect(() => {
    if(selectedSource === "" || selectedDestination === "") setOpen(true);
  });

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  // useEffect(() => {
  //   const source = localStorage.getItem("source") || "";
  //   const destination = localStorage.getItem("destination") || "";

  //   if(source !== "") setSelectedDestination(source);

  //   if(destination !== "") setSelectedSource(destination);
  // }, []);

  const body = (
    <Paper>
      <Box sx={{ p: "10px" }}>
        <Typography variant="subtitle1" color="textSecondary">
          Select source:
        </Typography>
        <Select
          name="select source"
          label="Select source"
          value={selectedSource}
          style={{ width: "100%" }}
          onChange={(ev: any) => {
            setSelectedSource(ev.target.value);
            localStorage.setItem("destination", ev.target.value);
            setError({
              source: false,
              destination: error.destination
            });
          }}
        >
          {source?.map((value) => {
            return <MenuItem value={value.id}>{value.name}</MenuItem>;
          })}
        </Select>
        {error.source && <FormHelperText style={{color: "red"}}>Please select source</FormHelperText>}

        <Typography
          variant="subtitle1"
          color="textSecondary"
          style={{ marginTop: "5vh" }}
        >
          Select destination:
        </Typography>
        <Select
          name="select destination"
          label="Select destination"
          value={selectedDestination}
          style={{ width: "100%" }}
          onChange={(ev: any) => {
            setSelectedDestination(ev.target.value);
            localStorage.setItem("source", ev.target.value);
            setError({
              source: error.source,
              destination: false
            });
          }}
        >
          {destination?.map((value) => {
            return <MenuItem value={value.id}>{value.name}</MenuItem>;
          })}
        </Select>
        {error.destination && <FormHelperText style={{color: "red"}}>Please select destination</FormHelperText>}
      </Box>
    </Paper>
  );

  const actions = (
    <>
			<Button
				variant='contained'
				style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
				type='button'
				onClick={() => {
          if(selectedSource === "") {
            setError({
              source: true,
              destination: error.destination
            });
          } else if(selectedDestination === "") {
            setError({
              source: error.source,
              destination: true
            });
          } else {
          handleClose();
          setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        }}
			>
				Ok
			</Button>
    </>
  )

  return (
    <>
      <Modal
        disableBackdropClick={false}
        isOpen={open}
        title="Select source & destination"
        handleClose={() => {
          if(selectedSource === "") {
            setError({
              source: true,
              destination: error.destination
            });
          } else if(selectedDestination === "") {
            setError({
              source: error.source,
              destination: true
            });
          } else {
          handleClose();
          setTimeout(() => {
              window.location.reload();
            }, 500);
          }
          handleClose();
        }}
        // minHeight="40vh"
        maxHeight="60%"
        maxWidth="xs"
        summary={<></>}
        actions={actions}
      >
        {body}
      </Modal>
    </>
  );
});

export default SourceDestinationSelection;
