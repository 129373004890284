import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, CardMedia, Typography, TextField } from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog';
import Backdrop from "../../shared/backdrop";
import NumericInput from 'react-numeric-input';
import PropTypes from 'prop-types';

const StockForm = forwardRef((props, ref) => {
	const {
		stock,
		source,
		destination,
		enqueueSnackbar,
		callBackStageQty,
		classes
	} = props;

	const [open, setOpen] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [note, setNote] = useState('');
	const [qty, setQty] = useState(0);
	const [maxSelect, setMaxSelect] = useState(0);
	const [product, setProduct] = useState({});

	// Expose methods to parent through ref
	useImperativeHandle(ref, () => ({
		handleOpen(productData) {
			setProduct(productData);
			setQty(productData.qty);
			setMaxSelect(productData.qty);
			setOpen(true);
		}
	}));

	// Event Handlers
	const handleQtyChange = (value) => {
		if (value > maxSelect || value <= 0 || value === null) return;
		setQty(value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setSubmitting(true);

		try {
			const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
			const response = await fetch(`/order_item_stages/${product.order_item_stage_id}/stock_movement`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken
				},
				body: JSON.stringify({ product, qty, note, source, destination })
			});

			if (!response.ok) {
				const error = await response.json();
				throw new Error(error.error || 'Unknown error');
			}

			enqueueSnackbar('Successfully processed!', { variant: 'success' });
			callBackStageQty(product.qty - qty);
			setOpen(false);
		} catch (error) {
			enqueueSnackbar(error.message, { variant: 'error' });
		} finally {
			setSubmitting(false);
		}
	};

	// Render
	const actions = (
		<Button
			variant="contained"
			style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
			onClick={handleSubmit}
		>
			Submit
		</Button>
	);

	return (
		<>
			<Backdrop open={submitting} />
			<Modal
				disableBackdropClick
				isOpen={open}
				title={stock ? 'Move item to stock' : 'Move item to shipment'}
				handleClose={() => setOpen(false)}
				maxHeight="60%"
				maxWidth="md"
				actions={actions}
			>
				<Card className={classes.root}>
					<CardMedia className={classes.cover}>
						<img src={product.image} alt={product.name} className={classes.image} />
					</CardMedia>
					<div className={classes.details}>
						<CardContent className={classes.content}>
							<Typography component="h5" variant="h5">{product.name}</Typography>
							<br />
							<Typography variant="subtitle1" color="textSecondary">
								QTY: <NumericInput min={1} max={maxSelect} value={qty} mobile size={4} onChange={handleQtyChange} />
							</Typography>
							{stock &&
								<TextField
									name="note"
									label="Comment"
									style={{ margin: 8 }}
									multiline
									fullWidth
									onChange={(e) => setNote(e.target.value)}
								/>
							}
						</CardContent>
					</div>
				</Card>
			</Modal>
		</>
	);
});

const useStyles = theme => ({
	root: { display: 'flex', padding: 10, margin: 5 },
	details: { display: 'flex', flexDirection: 'column', width: '100%' },
	content: { flex: '1 0 auto' },
	image: { height: '250px', width: '200px', objectFit: 'contain' },
});

StockForm.propTypes = {
	stock: PropTypes.bool.isRequired,
	source: PropTypes.string.isRequired,
	destination: PropTypes.string.isRequired,
	enqueueSnackbar: PropTypes.func.isRequired,
	callBackStageQty: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(StockForm);
