// tableConfig.js
import React from "react";
import Barcode from "../../order_items/_barcode";
import { IconButton, Link, FormControl, Badge, TextField, Checkbox, Select, MenuItem, FormControlLabel, Radio } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import pureIcons from "../../shared/pure_icons";
import { saveFilterSelection } from "../../shared/filter_selection";

const TABLE_COLUMNS = (component) => [
  {
    title: "Code",
    field: "redeem_code",
    sorting: false,
    filtering: false,
    cellStyle: { width: "2%" },
    render: (rowData) => (
      <IconButton aria-label="Barcode">
        <Barcode code={rowData.redeem_code} />
      </IconButton>
    ),
  },
  {
    title: "",
    field: "image",
    sorting: false,
    filtering: false,
    cellStyle: { width: "5%" },
    render: (row) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60px",
        }}
      >
        <Badge
          badgeContent="DG"
          invisible={!row.dg}
          overlap="circular"
          color="error"
        >
          <img
            style={{ height: "60px", width: "60px", objectFit: "contain" }}
            alt={row.name}
            src={row.image}
          ></img>
        </Badge>
      </div>
    ),
  },
  {
    title: "Product Name",
    field: "name",
    sorting: false,
    cellStyle: {
      whiteSpace: "normal",
      wordWrap: "break-word",
      width: "30%",
    },
    filtering: false,
    render: (rowData) => (
      <Link href={rowData.url} target="_blank">
        {rowData.name_short}
      </Link>
    ),
  },
  {
    title: "QTY",
    field: "qty",
    sorting: false,
    cellStyle: { width: "2%" },
    filtering: false,
  },
  {
    title: "Tracking",
    field: "tracking",
    sorting: false,
    filtering: false,
    editable: "never",
    cellStyle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 300,
    },
  },
  {
    title: "Shipping Method",
    field: "shipping_method",
    sorting: false,
    filterComponent: () => (
      <FormControl component="fieldset" style={{ width: "90%" }}>
        <Select
          value={component.shippingOption.current}
          onChange={(e) => {
            component.handleRoleFilterChange(e.target.value),
            (component.shippingOption.current = e.target.value);
            saveFilterSelection("hub-selected-filter", e.target.value);
          }}
          >
          {component.state.shippingMethods?.map((item) => {
            return (
              <MenuItem value={item} style={{ padding: "0rem 1rem" }}>
                <FormControlLabel
                  value={item}
                  control={
                    <Radio checked={component.shippingOption.current == item} />
                  }
                  label={item}
                  />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    ),
    render: (rowData) => <span>{rowData?.shipping_method}</span>,
  },
  {
    title: "Processed By",
    field: "who_did_it",
    sorting: false,
    filterComponent: ({ columnDef, onFilterChanged }) => (
      <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={component.state.hubOperators}
      disableCloseOnSelect
      limitTags={1}
      onChange={(e, newValue) => {
        onFilterChanged(columnDef.tableData.id, newValue);
      }}
      getOptionLabel={(option) => option}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={<pureIcons.CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<pureIcons.CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 1 }}
            checked={selected}
            />
          {option}
        </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="standard" />
        )}
        />
      ),
    },
    { title: "Vendor", field: "brand", filtering: false, sorting: false },
    {
      title: "Code",
      field: "redeem_code",
      sorting: false,
      filtering: false,
      editable: "never",
    },
    { title: "Time moved", field: "updated_at", filtering: false },

];

const TABLE_ACTIONS = (component, source) => [
  {
    icon: () => <pureIcons.BsBookshelf/>,
    tooltip: "Move to Stock",
    onClick: (event, rowData) => component._stockFormRef.current.handleOpen(rowData),
    position: "row",
  },
  {
    icon: () => <pureIcons.FiRefreshCw/>,
    tooltip: "Refresh",
    onClick: () => {component.reloadData()},
    isFreeAction: true,
  },
  {
    icon: () => <pureIcons.ImInfo/>,
    tooltip: <span>Show</span>,
    onClick: (event, rowData) => component._showItemRef.current.handleOpen(rowData.order_item_id, source),
    position: "row",
  },
  {
    icon: () => <pureIcons.BsChatDots/>,
    tooltip: <span>Notes</span>,
    onClick: (event, rowData) => component._noteFormRef.current.handleOpen(rowData.order_item_stage_id, source),
    position: "row",
  },
  {
    icon: () => <pureIcons.BsPlusLg/>,
    tooltip: "Add to Shipment",
    onClick: (event, rowData) => component._AddToListRef.current.handleOpen(rowData, source),
  },
  {
    tooltip: "Create Shipment",
    icon: () => <pureIcons.FiPackage/>,
    onClick: (event, rowData) => component._CreateShipmentRef.current.handleOpen(rowData, source),
  },
];

const CONFIG = {
  TABLE_COLUMNS,
  TABLE_ACTIONS,
};

export default CONFIG;
