export const fetchData = async (query, shippingOption, source, destination, component) => {
  let url = `/order_item_stages/stocked.json?shipping_option=${shippingOption}&source=${source}&location=${destination}`;
  url += "&per_page=" + query.pageSize;
  url += "&page=" + (query.page + 1);
  var filters;
  if (query.filters.length > 0) {
    filters = query.filters.map((col) => ({
      field: col.column.field,
      value: col.value,
    }));
    url += "&filters=" + JSON.stringify(filters);
  }
  if (query.orderBy) {
    url += "&orderBy=" + query.orderBy.field;
    url += "&orderDirection=" + query.orderDirection;
  }
  if (query.search) {
    url += "&search=" + query.search;
  }
  const response = await fetch(url);
  const result = await response.json();
  if (component.state.shippingMethods.length == 0) {
    component.setState({shippingMethods: result.shipping_methods, hubOperators: result.hub_operators});
    window.breadcrumb.addBreadcrumb(result.breadcrumb);
  }
  return {
    data: result.products,
    page: result.page - 1,
    totalCount: result.total,
  };
};

export const handleLockChange = async (rowData, source, component) => {
  try {
    const response = await fetch(`/order_item_stages/${rowData.order_item_stage_id}/switch_lock`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({source: source}),
      credentials: "include", // Ensures cookies are sent with the request
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "An error occurred");
    }

    const data = await response.json();
    component.reloadData(); // Reload the table or data source
    component.props.enqueueSnackbar(`Successfully ${data.state}!`, {
      variant: "success",
    });
  } catch (error) {
    component.props.enqueueSnackbar(error.message, {
      variant: "error",
    });
  }
};

