import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Badge, Link } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import Backdrop from "../../shared/backdrop";
import ShowItem from "../../order_items/show";
import Table from "../../shared/tables/material_table";
import { FiRefreshCw } from "react-icons/fi";
import Barcode from "../../order_items/_barcode";
import { ImInfo } from "react-icons/im";
import UpdateStageQty from "../../order_items/_update_stage_qty";

const PackingList = forwardRef((props, ref) => {
  const {source, destination} = props;
  const [working, setWorking] = useState(false);
  const [products, setProducts] = useState([]);
  const [groupId, setGroupId] = useState('');
  const [open, setOpen] = useState(false);
  const _ShowItemRef = useRef();

  useImperativeHandle(ref, () => ({
    handleOpen(groupId){
      setOpen(true);
      setGroupId(groupId);
      reloadData(groupId);
    }
	}));

  const handleClose = () => {
    setOpen(false);
  };

  const reloadData = (groupId) => {
    setWorking(true);
    const url = `/order_item_stages/packing_list.json?group_id=${groupId}&source=${source}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.products);
        setWorking(false);
      });
  };

  useEffect(() => {
    reloadData();
  }, []);

  const handleQtyUpdateCallback = () => {
    reloadData(groupId);
  };

  const columns = [
    {
      title: "",
      field: "image",
      sorting: false,
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            <img
              style={{ height: "60px", width: "60px", objectFit: "contain" }}
              alt={row.name}
              src={row.image}
            />
          </Badge>
        </div>
      ),
    },
    {
      title: "Product Name",
      field: "name",
      sorting: false,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        width: "30%",
      },
      render: (rowData) => (
        <Link href={rowData.url} target="_blank">
          {rowData.name_short}
        </Link>
      ),
    },
    {
      title: "QTY",
      field: "qty",
      sorting: false,
      cellStyle: { width: "2%" },
    },
    { title: "Order Number", field: "order_number" },
    { title: "Code", field: "redeem_code", sorting: false },
    { title: "Date", field: "created_at" },
  ];

  const actions = [
    {
      icon: () => <FiRefreshCw />,
      onClick: () => {
        reloadData(groupId);
      },
      isFreeAction: true,
      tooltip: "Refresh",
    },
    (rowData) => ({
      icon: () => <Barcode code={rowData.redeem_code} />,
      tooltip: "Barcode",
      position: "row",
    }),
    {
      icon: () => (
        <span style={{ padding: "0rem 1rem" }}>
          <ImInfo />
        </span>
      ),
      tooltip: "Show",
      onClick: (event, rowData) => _ShowItemRef.current.handleOpen(rowData.order_item_id, source),
      position: "row",
    },
    (rowData) => ({
      icon: () => (
        <UpdateStageQty
          product={rowData}
          table={true}
          callBackStageQty={handleQtyUpdateCallback}
          qty={rowData.qty}
          stage={"Ready to Ship"}
          location={destination}
          source={source}
        />
      ),
      tooltip: "Return to previous state",
      position: "row",
    }),
  ];

  const body = (
    <Table
      title={groupId}
      options={{
        toolbar: true,
        filtering: false,
        debounceInterval: 1000,
        paging: false,
        exportButton: true
      }}
      columns={columns}
      actions={actions}
      data={products}
    />
  );

  return (
    <div>
      <Backdrop open={working} />
      <ShowItem ref={_ShowItemRef} reloadData={reloadData} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title=""
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="lg"
      >
        {body}
      </Modal>
    </div>
  );
});

export default PackingList;
