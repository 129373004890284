import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Sidebar from "../components/shared/sidebar";
import ReceivedOrders from "../components/orders/received/index";
import Purchased from "../components/orders/purchased/index";
import VendorShipped from "../components/orders/vendor_shipped/index";
import CoreUsers from "../components/core_users/index";
import ReadyToShip from "../components/orders/ready_to_ship/index";
import Stocked from "../components/orders/stocked/index";
import CurrentPackingList from "../components/orders/current_packing_list/index";
import DummyPackage from "../components/dummy_package/index";
import ImageUploadForm from "../components/dummy_package/image_upload_form";
import PackingIssue from "../components/orders/exceptions/index";
import LimitLists from "../components/limit_lists/index";
import ShowLogs from "../components/logs/show";

export default (
  <Router>
    <div className="d-flex">
      <Route
        path="/"
        render={(props) => {
          switch (props.match.params.id) {
            default:
              return (
                <Sidebar
                  collapsed={localStorage.getItem("collapsed") || true}
                  page_id={props.match.params.id}
                />
              );
          }
        }}
      />
      <div id="content" collapsed={localStorage.getItem("collapsed") || true}>
        <Switch>
          <Route path="/stages/received" exact component={ReceivedOrders} />
          <Route path="/stages/purchased" exact component={Purchased} />
          <Route path="/logs/api_outbound_logs" exact component={(props) => <ShowLogs page={'api_outbound_logs'} title={'Show Outgoing Logs'}/>}/>
          <Route path="/logs/api_inbound_logs" exact component={(props) => <ShowLogs page={'api_inbound_logs'} title={'Show Incoming Logs'}/>}/>
          <Route
            path="/stages/vendor_shipped"
            exact
            component={VendorShipped}
          />
          <Route path="/core_users" exact component={CoreUsers} />
          <Route path="/order_item_stages/stocked" exact component={Stocked} />
          <Route
            path="/order_item_stages/warehouse"
            exact
            component={CurrentPackingList}
          />
          <Route
            path="/order_item_stages/ready_to_ship"
            exact
            component={ReadyToShip}
          />
          <Route
            path="/dummy_packages/image_upload_form"
            exact
            component={ImageUploadForm}
          />
          <Route path="/dummy_packages" exact component={DummyPackage} />
          <Route path="/order_item_stages/exceptions" exact component={PackingIssue} />
          <Route
            path="/order_items/product_limit_list"
            exact
            component={LimitLists}
          />
        </Switch>
      </div>
    </div>
  </Router>
);
