import React from "react";
import { FormControl, Select, MenuItem, FormControlLabel, Radio } from "@material-ui/core";
import pureIcons from "../../shared/pure_icons";
import { saveFilterSelection } from "../../shared/filter_selection";

const TABLE_COLUMNS = (component, source) => [
  {
    title: "Box Number",
    field: "group_id",
    sorting: false,
    editable: "never",
    filtering: false,
  },
  {
    title: "Items QTY",
    field: "items_qty",
    sorting: false,
    editable: "never",
    filtering: false,
  },
  { title: "Weight", field: "weight", sorting: false, filtering: false },
  {
    title: "Dimensions",
    field: "dimensions",
    sorting: false,
    filtering: false,
  },
  {
    title: "Status",
    field: "status",
    sorting: false,
    lookup: {
      Ready: "Ready",
      Ticket: "Ticket",
      Approved: "Approved",
      Return: "Return",
      Other: "Other",
      Special1: "Special1",
      Special2: "Special2",
    },
  },
  {
    title: "Shipping Method",
    field: "shipping_method",
    sorting: false,
    editable: "never",
    filterComponent: () => (
      <FormControl component="fieldset" style={{ width: "90%" }}>
        <Select
          value={component.shippingOption.current}
          onChange={(e) => {
            component.handleRoleFilterChange(e.target.value),
            (component.shippingOption.current = e.target.value);
            saveFilterSelection("hub-selected-filter", e.target.value);
          }}
          >
          {component.state.shippingMethods?.map((item) => {
            return (
              <MenuItem value={item} style={{ padding: "0rem 1rem" }}>
                <FormControlLabel
                  value={item}
                  control={
                    <Radio checked={component.shippingOption.current == item} />
                  }
                  label={item}
                  />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    ),
    render: (rowData) => <span>{rowData?.shipping_method}</span>,
  },
  {
    title: "Processed By",
    field: "who_did_it",
    sorting: false,
    editable: "never",
    filtering: false,
  },
  {
    title: "Date Created",
    field: "created_at",
    editable: "never",
    filtering: false,
  },
];

const TABLE_ACTIONS = (refs, reloadData) => [
  {
    icon: pureIcons.FiRefreshCw,
    onClick: () => reloadData(),
    isFreeAction: true,
    tooltip: "Refresh",
  },
  {
    icon: pureIcons.FaShippingFast,
    tooltip: "Add Carrier Tracking",
    onClick: (event, data) => refs._shippingFormRef.current.handleOpen(data),
  },
  {
    icon: pureIcons.Edit,
    tooltip: "Show packing list details",
    onClick: (event, rowData) => refs._PackingList.current.handleOpen(rowData.group_id),
    position: "row",
  },
  {
    icon: pureIcons.BsPlusLg,
    tooltip: "Add to Shipment",
    onClick: (event, data) =>
      refs._AddToShipment.current.handleOpen(data.map((p) => p.group_id)),
  },
  {
    tooltip: "export csv",
    icon: pureIcons.Export,
    onClick: (event, data) =>
      window.open(
        `/reports/shipping_list_summary?group_ids=${data.map(
          (p) => p.group_id
        )}`,
        "_self"
      ),
  },
  {
    tooltip: "Bulk Invoices",
    icon: pureIcons.GrMultiple,
    onClick: (event, data) =>
      window.open(`/reports/generate_invoices?group_ids=${data.map((p) => p.group_id)}`, "_self"),
  },
  {
    tooltip: "Invoice per Customer",
    icon: pureIcons.GrMultiple,
    position: "row",
    onClick: (event, data) =>
      window.open(`/reports/customers_invoices?group_id=${data.group_id}`, "_self"),
  },
];


const CONFIG = {
  TABLE_COLUMNS,
  TABLE_ACTIONS,
};

export default CONFIG;
