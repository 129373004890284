import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, MenuItem, TextField } from '@material-ui/core';
import InputMask from "react-input-mask";
import Modal from '../../shared/modal/material_dialog';
import { withSnackbar } from 'notistack';
import Backdrop from "../../shared/backdrop";
import Alert from '@material-ui/lab/Alert';

class CreateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      width: null,
      height: null,
      depth: null,
      weight: null,
      shipping_method: '',
      vol_weight: 0,
      errors: [],
      working: false,
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = (products, source) => {
    this.setState({
      products: products,
      open: true,
      units: this.props.units,
      width: null,
      height: null,
      depth: null,
      weight: null,
      vol_weight: 0,
			source: source,
      shipping_method: this.props.shipping_method
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.validate()) return;
    this.setState({ working: true });
    const payload = {
      products: this.state.products,
      width: this.state.width,
      height: this.state.height,
      depth: this.state.depth,
      weight: this.state.weight,
      shipping_method: this.state.shipping_method,
			source: this.state.source
    };

    try {
      const response = await fetch('/order_item_stages/create_packing_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify(payload),
        credentials: 'include',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'An error occurred');
      }

      this.setState({ working: false, open: false });
      this.props.enqueueSnackbar('Successfully processed!', { variant: 'success' });
      this.props.callBackCreateShipment();
    } catch (error) {
      this.props.enqueueSnackbar(error.message, { variant: 'error' });
      this.setState({ working: false });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.validate({ [name]: value });
  };

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };
    if ('width' in fieldValues) temp.width = fieldValues.width ? '' : 'Required';
    if ('height' in fieldValues) temp.height = fieldValues.height ? '' : 'Required';
    if ('depth' in fieldValues) temp.depth = fieldValues.depth ? '' : 'Required';
    if ('weight' in fieldValues) temp.weight = fieldValues.weight ? '' : 'Required';
    if ('shipping_method' in fieldValues) temp.shipping_method = fieldValues.shipping_method ? '' : 'Required';

    const { width, height, depth, weight } = {
      width: fieldValues.width || this.state.width,
      height: fieldValues.height || this.state.height,
      depth: fieldValues.depth || this.state.depth,
      weight: fieldValues.weight || this.state.weight,
    };

    if (width && height && depth && weight) {
      const vol_weight = (this.props.units[1] === 'in')
        ? ((width * height * depth) / 139).toFixed(2)
        : ((width * height * depth) / 6000).toFixed(2);
      this.setState({ vol_weight });
    }

    this.setState({ errors: { ...temp } });
    if (fieldValues === this.state) return Object.values(temp).every(x => x === '');
  };

  render() {
    const { classes } = this.props;
    const actions = (
      <Button
        variant="contained"
        style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
        type="submit"
        className={classes.button}
        onClick={this.handleSubmit}
      >
        Submit
      </Button>
    );

    const body = (
      <Card className={classes.root} style={{ minHeight: '30vh' }}>
        <div className="container pb-5">
          {this.state.weight < (this.state.vol_weight - (this.state.vol_weight * 0.1)) && (
            <Alert severity="error">
              {`The volumetric weight for this shipment is ${this.state.vol_weight}${this.props.units[0]}. Do you wish to continue?`}
            </Alert>
          )}
          <p>You have selected {this.state.products?.length} item{this.state.products?.length > 1 ? 's' : ''} to create new shipment</p>
          {/* Dimension Inputs */}
          <div className="row">
            <div className="col">
              <p>Dimensions:</p>
            </div>
            {['width', 'height', 'depth'].map((dim) => (
              <div className="col" key={dim}>
                <InputMask
                  mask="999"
                  value={this.state[dim]}
                  maskChar=" "
                  onChange={this.handleInputChange}
                >
                  {() => (
                    <TextField
                      name={dim}
                      label={`${dim.charAt(0).toUpperCase() + dim.slice(1)} (${this.props.units[1]})`}
                      className={classes.textField}
                      {...(this.state.errors[dim] && { error: true, helperText: this.state.errors[dim] })}
                    />
                  )}
                </InputMask>
              </div>
            ))}
          </div>
          {/* Weight Input */}
          <div className="row pt-3">
            <div className="col-3">
              <p>Weight:</p>
            </div>
            <div className="col-9">
              <InputMask
                mask="999"
                value={this.state.weight}
                maskChar=" "
                onChange={this.handleInputChange}
              >
                {() => (
                  <TextField
                    name="weight"
                    label={`Weight (${this.props.units[0]})`}
                    className={classes.textField}
                    {...(this.state.errors.weight && { error: true, helperText: this.state.errors.weight })}
                  />
                )}
              </InputMask>
            </div>
          </div>
        </div>
      </Card>
    );

    return (
      <>
        <Backdrop open={this.state.working} />
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title="New Shipment"
          handleClose={this.handleClose}
          maxHeight="400%"
          maxWidth="sm"
          actions={actions}
        >
          {body}
        </Modal>
      </>
    );
  }
}

const useStyles = (theme) => ({
  root: { display: 'flex', padding: 10, margin: 5 },
  textField: { margin: theme.spacing(1) },
  content: { flex: '1 0 auto' },
  cover: { objectFit: 'contain' },
});

export default withSnackbar(withStyles(useStyles)(CreateList));
