import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card } from "@material-ui/core";
import Select from "../../shared/autocomplete";
import Modal from "../../shared/modal/material_dialog";
import { withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";

class AddToListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      packing_list: "",
      errors: [],
      working: false,
      shipping_method: ""
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = (products, source) => {
    this.setState({
      products: products,
      open: true,
      shipping_method: this.props.shipping_method,
      source: source
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.validate()) return;

    this.setState({ working: true });

    const payload = {
      group_id: this.state.packing_list,
      products: this.state.products,
      shipping_method: this.state.shipping_method,
      source: this.state.source
    };

    try {
      const response = await fetch(`/order_item_stages/create_packing_list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred");
      }

      this.setState({ working: false, open: false });
      this.props.enqueueSnackbar("Successfully processed!", { variant: "success" });
      this.props.callBackAddToList();
    } catch (error) {
      this.props.enqueueSnackbar(error.message, { variant: "error" });
      this.setState({ working: false });
    }
  };

  handleSelectCallBack = (data) => {
    this.setState({ packing_list: data.value });
    this.validate({ packing_list: data.value });
  };

  validate = (fieldValues = this.state) => {
    const temp = { ...this.state.errors };
    temp.packing_list = fieldValues.packing_list ? "" : "Required";

    this.setState({ errors: temp });

    if (fieldValues === this.state) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  render() {
    const { classes } = this.props;
    const { open, shipping_method, errors, products, source } = this.state;

    const actions = (
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        type="submit"
        className={classes.button}
        onClick={this.handleSubmit}
      >
        Submit
      </Button>
    );

    const body = (
      <Card className={classes.root} style={{ minHeight: "30vh" }}>
        <div className="container pb-5">
          <p>
            You have selected {products?.length} item{products?.length > 1 ? "s" : ""} to add to an
            existing packing list.
          </p>
          <div className="row pt-2">
            <Select
              name="packing_list"
              label="Packing List"
              url={`/order_item_stages/ready_to_ship.json?shipping_option=${shipping_method}&source=${source}`}
              error={errors.packing_list}
              callBack={this.handleSelectCallBack}
            />
          </div>
        </div>
      </Card>
    );

    return (
      <>
        <Backdrop open={this.state.working} />
        <Modal
          disableBackdropClick
          isOpen={open}
          title="Add to Packing List"
          handleClose={this.handleClose}
          maxHeight="400%"
          maxWidth="sm"
          actions={actions}
        >
          {body}
        </Modal>
      </>
    );
  }
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 5,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    objectFit: "contain",
  },
});

export default withSnackbar(withStyles(useStyles)(AddToListForm));
