const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getUsers = async () => {
  const response = await fetch("/core_users.json");
  if (!response.ok) {
    throw new Error("Failed to fetch users.");
  }
  return response.json();
};

export const deleteUser = async (userId) => {
  const response = await fetch(`/core_users/${userId}`, { method: "DELETE", headers: DEFAULT_HEADERS });
  if (!response.ok) {
    throw new Error("Failed to delete user.");
  }
};

const handleResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }
  return response.json();
};

// API call function to submit user data
export const submitUserData = async (userData, setServerErrors) => {
  try {
    const response = await fetch('/core_users', {
      method: 'POST',
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(userData),
    });

    // Handle non-OK response
    if (!response.ok) {
      const errorData = await response.json(); // Parse JSON error message
      setServerErrors(errorData.error);
      throw new Error(errorData.error || 'Something went wrong');
    }

    return await response.json(); // Parse and return JSON on success
  } catch (error) {
    // Handle unexpected or network errors
    throw error;
  }
};


// API call function to update user
export const updateUser = async (userId, data, setServerErrors) => {
  try {
    const response = await fetch(`/core_users/${userId}`, {
      method: 'PATCH',
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(data),
    });

    // Handle the response and throw for non-OK statuses
    if (!response.ok) {
      const errorData = await response.json(); // Parse JSON error message
      setServerErrors(errorData.error);
      throw new Error(errorData.error || 'Something went wrong');
    }

    return await response.json(); // Return JSON response for successful requests
  } catch (error) {
    throw error; // Re-throw the error to allow further handling
  }
};
