import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import {
  Button,
  IconButton,
  Box,
  TextField,
  Typography
} from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import ProductCard from "./_shipping_card";
import ProductCardSkeleton from "../../shared/skeletons/product_card_skeleton";
import ShowOrder from "../_show";
import Backdrop from "../../shared/backdrop";
import Alert from "@material-ui/lab/Alert";
import CopyTextComponent from "../../shared/copy_clipboard";
import OrderInfo from "../received/_order_info";
import { useSnackbar } from "notistack";
import DatePicker from "../../shared/date_picker";

const ShippingForm = forwardRef(({ reloadData }, ref) => {
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [products, setProducts] = useState([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [orderSummary, setOrderSummary] = useState([]);
  const [vendor, setVendor] = useState("");
  const [orderId, setOrderId] = useState("");
  const [invoice, setInvoice] = useState("");
  const [shipped, setShipped] = useState([]);
  const [eta, setEta] = useState(null);
  const [tracking, setTracking] = useState("");
  const [working, setWorking] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [source, setSource] = useState("");
  const [groupId, setGroupId] = useState("");
  const [marketSource, setMarketSource] = useState("");
  const [newCustomer, setNewCustomer] = useState(false);
  const [shippingOption, setShippingOption] = useState("");

  // Refs
  const showOrderRef = useRef();

  // Expose functions to the parent via ref
  useImperativeHandle(ref, () => ({
    handleOpen(
      invoice,
      orderNumber,
      vendor,
      orderId,
      source,
      groupId,
      marketSource,
      newCustomer,
      shippingOption
    ) {
      setOpen(true);
      defaultValues();
      setOrderNumber(orderNumber);
      setVendor(vendor);
      setOrderId(orderId);
      setSource(source);
      setGroupId(groupId);
      setMarketSource(marketSource);
      setNewCustomer(newCustomer);
      setShippingOption(shippingOption);
      setInvoice(invoice);

      getProducts(invoice, source, shippingOption);
    }
  }));


  const handleClose = () => {
    defaultValues();
    setWorking(false);
    setOpen(false);
    const searchBar = document.querySelector(`#pure_table_toolbar_id input`);
    if (searchBar) searchBar.focus();
  };

  const getProducts = async (invoice, source, shippingOption) => {
    setWorking(true);
    const url = `/order_item_stages/items_to_ship?source=${source}&invoice=${invoice}&shipping_option=${shippingOption}`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Invalid response");
      const result = await response.json();
      setProducts(result.products);
      setOrderSummary(result.order_summary);
    } catch (error) {
      enqueueSnackbar(
        "Invalid invoice or items have been moved to the next stage",
        { variant: "error" }
      );
      handleClose();
    } finally {
      setWorking(false);
    }
  };

  const defaultValues = () => {
    setTracking("");
    setEta(null);
    setDuplicate(false);
    setShipped([]);
  }

  const handleAfterSubmit = async () => {
    const allSelected =
      shipped.length === products.length &&
      shipped.reduce((a, v) => a + v.qty, 0) ===
        products.reduce((a, v) => a + v.qty, 0);

    if (allSelected) {
      reloadData();
      handleClose();
    } else {
      defaultValues();
      await getProducts(invoice, source, shippingOption);
      setWorking(false);
    }
  };

  const validate = (fieldValues = {tracking, eta, shipped}) => {
    setErrors({});
    const tempErrors = { ...errors };
    if ("tracking" in fieldValues) {
      tempErrors.tracking = fieldValues.tracking ? "" : "Required";
    }
    if ("eta" in fieldValues) {
      tempErrors.eta = !fieldValues.eta
        ? "Required"
        : isNaN(new Date(fieldValues.eta).getTime())
        ? "Invalid Date"
        : "";
    }
    if ("shipped" in fieldValues) {
      if (fieldValues.shipped.length > 0) tempErrors.shipped = "";
      else {
        tempErrors.shipped = "One or more items must be selected!";
        enqueueSnackbar("One or more items must be selected!", {
          variant: "error",
        });
      }
    }
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;
    setWorking(true);

    const payload = {
      shipped,
      tracking,
      eta,
      group_id: groupId,
      invoice,
      vendor,
      source,
      market_source: marketSource,
      new_customer: newCustomer,
      duplicate,
      order_number: orderNumber,
      shipping_option: shippingOption,
    };

    try {
      const response = await fetch(`/orders/ship`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        // Extract error details if available
        const errorData = await response.json();
        const errorMessage = errorData.error || "An error occurred";
        throw new Error(errorMessage);
      }
      handleAfterSubmit();
      enqueueSnackbar("Successfully processed!", { variant: "success" });
    } catch (error) {
      if (error.message == "tracking number already exists") {setDuplicate(true);}
      enqueueSnackbar(error.message || "An error occurred", { variant: "error" });
      setWorking(false);
    } finally {

    }
  };


  handleInputChange = (e) => {
    const { name, value } = e.target;
    setTracking(value);
    validate({ [name]: value });
  };

  selectedDateValue = (date) => {
    setEta(date);
    validate({ eta: date });
  };

  const setProductData = (data) => {
    if (data.selected) {
      const obj = shipped.find(
        (o) => o.order_item_stage_id === data.order_item_stage_id
      );

      if (obj) {
        const replace = shipped.filter(
          (o) => o.order_item_stage_id !== data.order_item_stage_id
        );
        replace.push(data);
        setShipped(replace);
      } else {
        setShipped([...shipped, data]);
      }
    } else {
      if (data.deleted) {
        const replace = products.filter(
          (o) => o.order_item_stage_id !== data.order_item_stage_id
        );

        if (products.length === 1) {
          handleClose();
          reloadData(true);
        } else {
          setProducts(replace);
        }
      }

      const replace = shipped.filter(
        (o) => o.order_item_stage_id !== data.order_item_stage_id
      );
      setShipped(replace);
    }
  };


  const summary = (
    <div className="container">
      <div className="row ms-4">
        {/* Selected Items Summary */}
        <div className="col">
          <Box pt={2} mt={1}>
            <Typography variant="button" color="textSecondary">
              Selected: {shipped.length}/{products.length}
            </Typography>
          </Box>
        </div>

        {/* Tracking Number Input */}
        <div className="col">
          <TextField
            fullWidth
            name="tracking"
            value={tracking}
            label="Tracking number"
            error={!!errors.tracking}
            helperText={errors.tracking}
            onChange={handleInputChange}
          />
        </div>

        {/* ETA Date Picker */}
        <div className="col">
          <DatePicker
            disablePast
            name="eta"
            label="ETA"
            selectedDateValue={selectedDateValue}
            value={eta}
            error={!!errors.eta}
            helperText={errors.eta}
          />
        </div>
      </div>
    </div>
  );


  return (
    <div>
      <Backdrop open={working} />
      <ShowOrder ref={showOrderRef} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={
          <>
            <Button onClick={() => showOrderRef.current.handleOpen(orderNumber, source)} style={{ fontSize: "inherit", color: "#f6b67f", fontWeight: "bold" }}>
              {orderNumber}
            </Button>
            <IconButton
              onClick={() => navigator.clipboard.writeText(orderNumber)}
            >
              <CopyTextComponent textValue={orderNumber} />
            </IconButton>
            <OrderInfo orderSummary={orderSummary} />
            {`(${vendor} - ${invoice})`}
          </>
        }
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="lg"
        summary={summary}
        actions={
          <>
            {duplicate && (
              <Alert severity="error">
                Tracking number already exists in the system!
              </Alert>
            )}
            <Button
              onClick={handleSubmit}
              style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
              variant="contained"
              color="primary"
            >
              {duplicate ? "Submit duplicate" : "Submit"}
            </Button>
          </>
        }
      >
        {working ? (
          <ProductCardSkeleton />
        ) : (
          products.map((p) => (
            <ProductCard
              key={p.id}
              product={p}
              callBack={setProductData}
              selected={shipped.find((s) => s.order_item_stage_id === p.order_item_stage_id)}
              source={source}
            />
          ))
        )}
      </Modal>
    </div>
  );
});

export default ShippingForm;
