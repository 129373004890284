import React from "react";
import pureIcons from "../shared/pure_icons";
import { Badge } from "@material-ui/core";

export const userColumns = [
  {
    title: "Name",
    field: "full_name",
    render: (rowData) =>
      rowData.admin ? (
        <Badge badgeContent="ADMIN" color="secondary">
          {rowData.full_name}
        </Badge>
      ) : (
        rowData.full_name
      ),
  },
  { title: "Phone Number", field: "phone_number" },
  { title: "Roles", field: "user_roles_pretty" },
  { title: "Last Active", field: "last_seen_at", emptyValue: 'Never' }
];

export const userActions = ({ handleDelete, reloadUsers, userFormRef, userRolesList }) => [
  {
    icon: () => <pureIcons.AiOutlinePlus />,
    tooltip: "Add New User",
    isFreeAction: true,
    onClick: () => userFormRef.current.handleOpen(undefined, userRolesList, 'add'),
  },
  (rowData) => ({
    icon: () => <pureIcons.Edit />,
    tooltip: "Edit",
    onClick: () => userFormRef.current.handleOpen(rowData, userRolesList, 'edit'),
  }),
  (rowData) => ({
    icon: () => <pureIcons.Delete />,
    tooltip: "Delete",
    disabled: !rowData.deletable,
    onClick: () => handleDelete(rowData.id, rowData.full_name),
  }),
  {
    icon: () => <pureIcons.FiRefreshCw />,
    tooltip: "Refresh",
    isFreeAction: true,
    onClick: reloadUsers,
  },
];
