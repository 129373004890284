import React, { useState, useImperativeHandle, forwardRef } from 'react';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Modal from '../shared/modal/material_dialog';
import { useSnackbar } from 'notistack';
import Backdrop from '../shared/backdrop';
import { submitUserData, updateUser } from './api';

const CoreUserModal = forwardRef(({ reloadData }, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [working, setWorking] = useState(false);
  const [mode, setMode] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const [values, setValues] = useState({
    phone_number: '',
    password: '',
    first_name: '',
    last_name: '',
    user_roles: [],
  });
  const [errors, setErrors] = useState({});
  const [serverErrors, setServerErrors] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  // Expose methods to parent components
  useImperativeHandle(ref, () => ({
    handleOpen: (user, list, mode) => {
      setServerErrors('')
      setCurrentUser(null)
      setMode(mode)
      if (mode === 'edit') {
        setValues(user);
        setCurrentUser(user);
      } else {
        setValues({
          phone_number: '',
          password: '',
          first_name: '',
          last_name: '',
          user_roles: [],
        });
      }
      setUserRoles(list);
      setOpen(true);
    },
    closeModal: () => {
      setOpen(false);
    },
  }));

  const closeModal = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setValues((prevState) => {
      const updatedRoles = checked
        ? [...prevState.user_roles, name]
        : prevState.user_roles.filter((role) => role !== name);
      return { ...prevState, user_roles: updatedRoles };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
    validate({ [name]: value });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setServerErrors('')
    // Clear any previous server error
    temp.serverErrors = '';

    // Validate first_name and last_name for English-only characters
    const englishOnlyRegex = /^[a-zA-Z\s]*$/;

    if ('first_name' in fieldValues) {
      temp.first_name = fieldValues.first_name
        ? englishOnlyRegex.test(fieldValues.first_name)
          ? ''
          : 'First name must contain only English letters'
        : 'First name is required';
    }

    if ('last_name' in fieldValues) {
      temp.last_name = fieldValues.last_name
        ? englishOnlyRegex.test(fieldValues.last_name)
          ? ''
          : 'Last name must contain only English letters'
        : 'Last name is required';
    }

    if ('phone_number' in fieldValues) {
      temp.phone_number = fieldValues.phone_number ? '' : 'Phone number is required';
    }

    if ('password' in fieldValues && mode === 'add') {
      temp.password = fieldValues.password ? '' : 'Password is required';
    }

    setErrors({ ...temp });
    // Return true if there are no validation errors
    if (fieldValues === values) return Object.values(temp).every((x) => x === '');
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setWorking(true);

    try {
      // Call submitUserData function from api.js
      if (mode === 'edit') {
        await updateUser(currentUser.id, values, setServerErrors); // Update an existing user
      } else {
        await submitUserData(values, setServerErrors); // Submitting data for new user
      }

      enqueueSnackbar('User successfully saved!', { variant: 'success' });
      reloadData();
      setOpen(false);
      setWorking(false);
    } catch (error) {
      console.error(error);
    } finally {
      setWorking(false);
    }
  };

  const renderRolesCheckboxes = (roles) =>
    roles.map((role, index) => (
      <div className="col-4" key={index}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.user_roles.includes(role[0])}
              onChange={handleChange}
              name={role[0]}
              color="primary"
            />
          }
          label={role[1]}
        />
      </div>
    ));

  const renderBody = () => {
    if (mode === 'add') {
      return (
        <>
        {serverErrors && <div className="alert alert-danger" role="alert">{serverErrors}</div>}
        {['phone_number', 'password', 'first_name', 'last_name'].map((field) => (
          <div className="mb-3" key={field}>
            <TextField
              label={field.replace('_', ' ').toUpperCase()}
              type={field === 'password' ? 'password' : 'text'}
              name={field}
              fullWidth
              value={values[field]}
              onChange={handleInputChange}
              error={!!errors[field]} // Checks if there's an error for this field
              helperText={errors[field]} // Displays the error message
            />
          </div>
        ))}
        <div className="container pb-5">
          <div className="row">{renderRolesCheckboxes(userRoles)}</div>
        </div>
        </>
      );
    }
    return (
      <div className="container pb-5">
        {serverErrors && <div class="alert alert-danger" role="alert">{serverErrors}</div>}
        {['first_name', 'last_name'].map((field) => (
          <div className="mb-3" key={field}>
            <TextField
              label={field.replace('_', ' ').toUpperCase()}
              type={field === 'password' ? 'password' : 'text'}
              name={field}
              fullWidth
              value={values[field]}
              onChange={handleInputChange}
              error={!!errors[field]} // Checks if there's an error for this field
              helperText={errors[field]} // Displays the error message
            />
          </div>
        ))}
        <div className="row">{renderRolesCheckboxes(userRoles)}</div>
      </div>
    );
  };

  const actions = (
    <Button
      variant="contained"
      style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
      disabled={working}
      onClick={handleSubmit}
    >
      {working ? 'Working...' : 'Submit'}
    </Button>
  );

  return (
    <Modal
      isOpen={open}
      title={mode === 'edit' ? currentUser?.full_name : 'Add User'}
      handleClose={closeModal}
      minHeight="40vh"
      maxHeight="60%"
      maxWidth="sm"
      actions={actions}
    >
      <Backdrop open={working} />
      {renderBody()}
    </Modal>
  );
});

export default CoreUserModal;
