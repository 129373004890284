import React from "react";
import { Button, CircularProgress, Box, IconButton } from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import ItemCard from "./_item_card";
import Typography from "@material-ui/core/Typography";
import ProductCardSkeleton from "../../shared/skeletons/product_card_skeleton";
import ShowOrder from "../_show";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import $ from "jquery";
import Backdrop from "../../shared/backdrop";
import CopyTextComponent from "../../shared/copy_clipboard";

class HubProcessForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errors: {},
      working: false,
    };
    this._ShowOrderRef = React.createRef();
  }

  handleOpen = (tracking, order_number, vendor, invoice, source) => {
    this.setState({
      open: true,
      order_number: order_number,
      tracking: tracking,
      invoice: invoice,
      vendor: vendor,
      errors: [],
      source: source,
      eta: null
    });
    this.getProducts(tracking, source, this.props.orderState);
  };

  handleClose = () => {
    this.setState({ open: false });
    const searchBar = document.querySelector(`#pure_table_toolbar_id input`);
    if (searchBar) searchBar.focus();
  };

  getProducts = (tracking, source, orderState) => {
    this.setState({ working: true });
    const url = `/order_item_stages/items_to_process_in_hub.json?tracking=${tracking}&source=${source}&shipping_option=${orderState}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        result.products.length === 0
          ? this.handleClose()
          : this.setState({
              products: result.products,
              working: false,
              received: [],
            });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          "Invalid tracking or items has been moved to the next stage!",
          { variant: "error" }
        );
        this.handleClose();
      });
  };

  validate = (fieldValues = this.state) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = "";
    if ("received" in fieldValues) {
      if (fieldValues.received.length > 0) temp.received = "";
      else {
        temp.received = "One or more items must be selected!";
        this.props.enqueueSnackbar("One or more items must be selected!", {
          variant: "error",
        });
      }
    }
    this.setState({ errors: { ...temp } });

    if (fieldValues === this.state)
      return Object.values(temp).every((x) => x === "");
  };

  handleAfterSubmit = () => {
    if (
      this.state.received.length === this.state.products.length &&
      this.state.received.reduce((a, v) => (a = a + v.qty), 0) ===
        this.state.products.reduce((a, v) => (a = a + v.qty), 0)
    ) {
      this.handleClose();
    } else {
      this.getProducts(
        this.state.tracking,
        this.state.source,
        this.props.orderState
      );
    }
  };

  handleSubmit = (event, exception) => {
    let self = this;
    if(self.props.shippingMethod == 'All') {
      self.props.enqueueSnackbar('Please select shipping method', {variant: "error"});
      return;
    }
    event.preventDefault();
    if (!this.validate()) return;
    this.setState({ submitting: true });
    const data = {
      received: this.state.received,
      tracking: this.state.tracking,
      order_number: this.state.order_number,
      source: this.state.source,
      invoice: this.state.invoice,
      exception: exception
    };
    $.ajax({
      url: `/orders/hub_received`,
      type: "POST",
      data: data,
      dataType: "json",
      success: function (response) {
        self.setState({ submitting: false });
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
        self.props.reloadData(true);
        self.handleAfterSubmit();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ submitting: false });
        this.handleClose();
      },
    });
  };

  setProductData = (data) => {
    if (data.selected) {
      let obj = this.state.received.find(
        (o) => o.order_item_stage_id === data.order_item_stage_id
      );
      if (obj) {
        let replace = this.state.received.filter(
          (o) => o.order_item_stage_id !== data.order_item_stage_id
        );
        replace.push(data);
        this.setState({ received: replace });
      } else {
        this.setState((prevState) => ({
          received: [...prevState.received, data],
        }));
      }
    } else {
      if (data.deleted) {
        let replace = this.state.products.filter(
          (o) => o.order_item_stage_id !== data.order_item_stage_id
        );
        if (this.state.products.length === 1) {
          this.props.reloadData(true);
          this.handleClose();
        } else {
          this.setState({ products: replace });
        }
      }
      let replace = this.state.received.filter(
        (o) => o.order_item_stage_id !== data.order_item_stage_id
      );
      this.setState({ received: replace });
    }
  };

  render() {
    const { classes } = this.props;
    const body = this.state.products?.map((p) => (
      <ItemCard
        product={p}
        callBack={this.setProductData}
        key={p.order_item_stage_id}
        reloadData={this.props.reloadData}
        source={this.state.source}
      />
    ));

    const summary = (
      <div className="container">
        <div className="row ms-4">
          <div className="col">
            <Box pt={2} mt={1}>
              <Typography variant="button" color="textSecondary">
                Selected: {this.state.received?.length}/
                {this.state.products?.length}
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    );

    const actions = this.state.submitting ? (
      <CircularProgress className={classes.button} />
    ) : (
      <>
        <Button
          variant="contained"
          type="submit"
          className={classes.button}
          onClick={e => this.handleSubmit(e, true)}
        >
          Create issue
        </Button>
        <div style={{flex: '1 0 0'}} />
        <Button
          variant="contained"
          style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
          type="submit"
          className={classes.button}
          onClick={e => this.handleSubmit(e, false)}
        >
          Submit
        </Button>
      </>
    );

    const orderTitle = (
      <>
        <Button
          style={{ fontSize: "inherit", color: "#f6b67f", fontWeight: "bold" }}
          onClick={() =>
            this._ShowOrderRef.current.handleOpen(
              this.state.order_number,
              this.state.source
            )
          }
        >
          {this.state.order_number}
        </Button>
        <IconButton
          onClick={() => navigator.clipboard.writeText(this.state.order_number)}
          aria-label="copy"
        >
          <CopyTextComponent textValue={this.state.order_number} />
        </IconButton>
        {`(${this.state.vendor} ${this.state.tracking}) ${this.state.invoice}  `}
        <div className="badge badge-info pl-3">
          {this.state.source == "iq" ? "IRAQ" : "KSA"}
        </div>
      </>
    );
    return (
      <div>
        <ShowOrder ref={this._ShowOrderRef} />
        <Backdrop open={this.state.submitting} />
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title={orderTitle}
          handleClose={this.handleClose}
          minHeight="40vh"
          maxHeight="85%"
          maxWidth="lg"
          summary={summary}
          actions={actions}
        >
          {this.state.working ? <ProductCardSkeleton /> : body}
        </Modal>
      </div>
    );
  }
}

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
});

export default withSnackbar(withStyles(useStyles)(HubProcessForm));
