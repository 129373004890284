import React from "react";
import Table from "../../shared/tables/new_core_material_table";
import ShippingForm from "./_shipping_form";
import { withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import AutoControlPanel from "./_auto_control_panel";
import { getFilterSelection } from "../../shared/filter_selection";
import CONFIG from "./tableConfig";
import { fetchData, handleUpdate } from "./api";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      working: false,
      total: null,
      muiTableKey: 0,
      open: false,
      order_number: "",
      shippingMethods: [],
    };
    this.shippingOption = React.createRef("All");
    this.shippingOption.current = getFilterSelection("hub-selected-filter") || props.initialValue || "All";
    this._tableRef = React.createRef();
    this._ShippingFormRef = React.createRef();
    this._AutoControlPanelRef = React.createRef();
  }

  reloadData = (fullReload = false) => {
    if (fullReload) this.setState({ muiTableKey: this.state.muiTableKey + 1 });
    this._tableRef.current.onQueryChange();
  };

  handleRoleFilterChange = (val) => {
    this.setState({ setSelectedRoles: val });
    this.shippingOption.current = val;
    this.reloadData();
  };

  render() {
    const destination = localStorage.getItem("destination");
    const source = localStorage.getItem("source");
    return (
      <div className="bg-white rounded">
        <Backdrop open={this.state.working} />
        <ShippingForm ref={this._ShippingFormRef} reloadData={this.reloadData} />
        <AutoControlPanel ref={this._AutoControlPanelRef} />
        <Table
          title={"Purchased Orders"}
          key={this.state.muiTableKey}
          options={{
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            focus: true,
          }}
          localization={{
            toolbar: { searchPlaceholder: "invoice or order number" },
          }}
          columns={CONFIG.TABLE_COLUMNS(this)}
          tableRef={this._tableRef}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (
                    columnDef.field === "invoice_total" &&
                    (rowData.subtotal + rowData.subtotal * 0.025 < newValue ||
                      rowData.subtotal - rowData.subtotal * 0.1 > newValue)
                  )
                    window.confirm(
                      `Invoice is different than subtotal (${rowData.subtotal.toFixed(
                        2
                      )})`
                    ) &&
                      handleUpdate(
                        rowData.raw_invoice,
                        newValue,
                        columnDef,
                        rowData.order_number,
                        this
                      );
                  else
                    handleUpdate(
                      rowData.raw_invoice,
                      newValue,
                      columnDef,
                      rowData.order_number,
                      rowData.source,
                      this
                    );
                  resolve();
                }, 500);
              });
            },
          }}
          actions={CONFIG.TABLE_ACTIONS(this)}
          data={(query) => fetchData(query, source, destination, this)}
        />
      </div>
    );
  }
}
export default withSnackbar(Index);
