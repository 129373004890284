import React, { forwardRef, useImperativeHandle, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, MenuItem, TextField } from "@material-ui/core";
import Select from "../../shared/autocomplete";
import Modal from "../../shared/modal/material_dialog";
import { useSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 20,
    margin: 10,
    flexDirection: "column",
  },
  headerText: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
  },
  row: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    backgroundColor: "#f6b67f",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#f4a55e",
    },
  },
}));

const AddToListForm = forwardRef(({ reloadData, shipping_methods, source, destination }, ref) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Component state
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState([]);
  const [tracking, setTracking] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [shippingMethod, setShippingMethod] = useState("");

  // Open modal
  useImperativeHandle(
    ref,
    () => ({
      handleOpen(data) {
        setOpen(true);
        setValues(data);
        setErrors({});
        setTracking(null);
      },
    }),
    []
  );

  // Close modal
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  // Validation logic
  const validate = useCallback(() => {
    const newErrors = {};
    if (!tracking) newErrors.tracking = "Tracking is required.";
    if (!shippingMethod && shipping_methods?.length > 1) {
      newErrors.shippingMethod = "Shipping method is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [tracking, shippingMethod, shipping_methods]);

  // Form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setSubmitting(true);

    try {
      const response = await fetch(
        `/order_item_stages/add_to_shipment?source=${source}&location=${destination}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ data: values, tracking }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || "An error occurred");
      }

      enqueueSnackbar("Successfully processed!", { variant: "success" });
      reloadData();
      handleClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const handleSelectCallback = (data) => {
    setTracking(data.value);
  };

  const body = (
    <Card className={classes.root}>
      <p className={classes.headerText}>
        You have selected {values.length} box{values.length !== 1 ? "es" : ""} to add to an existing shipment.
      </p>

      {/* Tracking Input */}
      <div className={classes.row}>
        <Select
          name="tracking"
          label="Tracking"
          url={`/order_item_stages/in_transit_list.json?source=${source}&location=${destination}`}
          error={errors.tracking}
          callBack={handleSelectCallback}
        />
      </div>

      {/* Shipping Method Input (if applicable) */}
      {shipping_methods?.length > 1 && (
        <div className={classes.row}>
          <TextField
            label="Shipping Method"
            select
            fullWidth
            value={shippingMethod}
            error={!!errors.shippingMethod}
            helperText={errors.shippingMethod}
            onChange={(event) => setShippingMethod(event.target.value)}
          >
            {shipping_methods.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}
    </Card>
  );

  const actions = (
    <Button
      variant="contained"
      className={classes.submitButton}
      onClick={handleSubmit}
      disabled={submitting}
    >
      {submitting ? "Submitting..." : "Submit"}
    </Button>
  );

  return (
    <>
      <Backdrop open={submitting} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title="Add to Shipment"
        handleClose={handleClose}
        maxHeight="400%"
        maxWidth="sm"
        actions={actions}
      >
        {body}
      </Modal>
    </>
  );
});

export default AddToListForm;
