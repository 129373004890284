import React, { useEffect, useState } from "react";
import Table from "../shared/tables/material_table";
import Backdrop from "../shared/backdrop";
import $ from "jquery";
import { useSnackbar } from "notistack";

const LimitLists = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);

  const { enqueueSnackbar } = useSnackbar();

  const getData = () => {
    setLoading(true);
    $.ajax({
      url: `/order_items/product_limit_list.json`,
      type: "GET",
      success: function (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        setTableData(response.data);
        setLoading(false);
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Product Id",
      field: "ProductId",
      sorting: false,
      filtering: false,
      editable: "never",
    },
    {
      title: "Name",
      field: "Name",
      filtering: false,
      sorting: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 500,
      },
    },
    {
      title: "Brand",
      field: "Brand",
      sorting: false,
      filtering: false,
      editable: "never",
    },
    {
      title: "Quantity",
      field: "Quantity",
      filtering: false,
      editable: "never",
    },
    {
      title: "Days",
      field: "Days",
      filtering: false,
    },
    {
      title: "Remaining QTY",
      field: "Remaining",
      sorting: false,
      filtering: false,
      editable: "never",
    },
    {
      title: "Recurrent",
      field: "Recurrent",
      sorting: false,
      filtering: false,
      editable: "never",
    },
    {
      title: "TTL",
      field: "TTL",
      filtering: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 200,
      },
    },
    {
      title: "Timestamp",
      field: "Timestamp",
      filtering: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 200,
      },
    },
  ];

  return (
    <>
      <div className="bg-white rounded">
        <Backdrop open={loading} />
        <Table
          title="Limit Lists"
          options={{
            toolbar: true,
            debounceInterval: 1000,
            actionsColumnIndex: -1,
          }}
          columns={columns}
          data={tableData}
        />
      </div>
    </>
  );
};

export default LimitLists;
