import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import Table from "../shared/tables/material_table";
import Backdrop from "../shared/backdrop";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import pureIcons from "../shared/pure_icons";
import FormPage from "./form";
import NoteDummyPackage from "./note_dummy_package";

const DummyPackage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [dummyPackages, setDummyPackages] = useState<any>(null);
  const [dummyPackagesGlobal, setDummyPackagesGlobal] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const _Dummy_Package = useRef<any>(null);
  const _dummy_package_note = useRef<any>(null);
  const [statusData, setStatusData] = useState<any>();
  const statusOptions = useRef<any>([]);
  const filterOptions = {
    "need more information": "need more information",
    "not in the system": "not in the system",
    resolved: "resolved",
    closed: "closed",
  };
  const filterOptionNotLoggedIN = {
    "need more information": "need more information",
    resolved: "resolved",
  };

  const getData = () => {
    // setLoading(true);
    // setDummyPackages(dummyData);
    const url = `/dummy_packages.json`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setDummyPackages(data.dummy_packages);
        setDummyPackagesGlobal(data.dummy_packages);
        setStatusData(data.statuses);
      });
  };

  const handleFilter = (newValue, rowData, columnDef) => {
    let filterData = dummyPackagesGlobal.filter(
      (value) => value.status == newValue
    );
    setDummyPackages(filterData);
  };
  const searchParams = new URLSearchParams(window.location.search);
  const sourceFromUrl = searchParams.get("source");

  useEffect(() => {
    const destination = localStorage.getItem("destination");
    history.push(
      `/dummy_packages?source=${
        localStorage.getItem("sidebarLength") > 0
          ? destination?.toLocaleUpperCase()
          : sourceFromUrl
          ? sourceFromUrl
          : "US"
      }`
    );
  }, []);

  const columns = [
    {
      title: "Tracking Number",
      field: "tracking",
      sorting: false,
      editable: "never",
      filtering: false,
    },
    {
      title: "Region",
      field: "region",
      sorting: false,
      editable: "never",
      filtering: false,
    },

    {
      title: "Status",
      field: "status",
      sorting: false,
      editable: "never",
      filtering: true,
      lookup:
        localStorage.getItem("sidebarLength") > 0
          ? filterOptions
          : filterOptionNotLoggedIN,
    },
    {
      title: "Created At",
      field: "created_at",
      sorting: false,
      editable: "never",
      filtering: false,
    },

    // {
    //   title: "Market source",
    //   field: "market_source",
    //   sorting: false,
    //   editable: "never",
    //   filtering: false,
    // },
    // {
    //   title: "Images",
    //   field: "images",
    //   sorting: false,
    //   editable: "never",
    //   filtering: false,
    // },
  ];

  return (
    <>
      <div className="bg-white rounded">
        <Backdrop open={loading} />
        <FormPage ref={_Dummy_Package} />
        <NoteDummyPackage ref={_dummy_package_note} />
        <Table
          title="Dummy package"
          options={{
            toolbar: true,
            debounceInterval: 1000,
            actionsColumnIndex: -1,
            filter: true,
          }}
          // cellEditable={{
          //   cellStyle: {
          //     width: "15%",
          //   },
          //   onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          //     return new Promise((resolve: any, reject) => {
          //       setTimeout(() => {
          //         handleFilter(newValue, rowData, columnDef);
          //         resolve();
          //       }, 500);
          //     });
          //   },
          // }}
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              var filters;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }
              const destination = localStorage.getItem("destination");
              let url = `/dummy_packages.json?source=${
                localStorage.getItem("sidebarLength") > 0
                  ? destination
                  : sourceFromUrl
              }&`;
              url += "per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.filters.length > 0) {
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.dummy_packages,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  statusOptions.current = result.statuses;
                  setStatusData(result.statuses);
                });
            })
          }
          // data={dummyPackages || []}
          actions={[
            {
              icon: () => <pureIcons.Pending />,
              onClick: () => {
                _Dummy_Package.current.handleOpen(
                  "Add",
                  "",
                  statusOptions.current
                );
              },
              isFreeAction: true,
              tooltip: "Create dummy/pending packages",
            },
            (rowData) => ({
              icon: () => <pureIcons.Edit />,
              onClick: () => {
                _Dummy_Package.current.handleOpen(
                  "Edit",
                  rowData,
                  statusOptions.current
                );
              },
              tooltip: <span>Edit</span>,
              position: "row",
            }),
            // {
            //   icon: pureIcons.BsChatDots,
            //   tooltip: <span>Notes</span>,
            //   onClick: (event, rowData) => {
            //     _dummy_package_note.current.handleOpen(rowData);
            //   },
            //   position: "row",
            // },
          ]}
        />
      </div>
    </>
  );
};

export default DummyPackage;
