import React, {useRef} from 'react';
import $ from 'jquery';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import NoteForm from '../../notes/_note_form'
import DeleteItem from '../../order_items/_cancel_form'
import {Checkbox} from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {withSnackbar} from 'notistack';
import InfoIcon from '@material-ui/icons/Info';
import ShowItem from '../../order_items/show'
import { green } from '@material-ui/core/colors';
class SpeedDials extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
		this._ShowItemRef = React.createRef();
	}

	handleLockChange = (e) => {
		let self = this;
		$.ajax({
			url: `/order_item_stages/switch_lock?id=${this.props.product.order_item_stage_id}&source=${this.props.source}`,
			type: 'POST',
			dataType: 'json',
			success: function (response) {
				self.props.callBackLockState(response.state === 'locked')
				self.props.enqueueSnackbar(`successfully updated!`, { variant: 'success' })
			},
			error: function (response) {
				self.props.enqueueSnackbar(response.responseJSON.error, { variant: 'error' })
			},
		});
	}

	handleClose = () => {
		this.setState({open: false});
	};

	handleOpen = () => {
		this.setState({open: true});
	};
	render() {
		const actions = [
			{ icon: <NoteForm order_item_stage_id={this.props.product.order_item_stage_id} key={this.props.product.order_item_stage_id} newNoteChild={this.props.newNote} source={this.props.source}/>, name: 'Add note' },
			{ icon: <InfoIcon onClick={() => this._ShowItemRef.current.handleOpen(this.props.product.order_item_id, this.props.source)}/>, name: 'Show' },
			{icon: <Checkbox icon={<LockIcon />} checkedIcon={<LockOpenIcon style={{ color: green[500] }}/>}  onChange={this.handleLockChange} defaultChecked={this.props.product.locked}/>, name: 'Lock'},
			{icon: <DeleteItem product={this.props.product} callBackDeleteItem={this.props.handleDeleteItem} qty={this.props.product.qty} stage={'Placed with the vendor'} order_item_stage_id={this.props.product.order_item_stage_id} source={this.props.source}/>, name: 'Delete'},
		];

		return (
			<>
				<ShowItem ref={this._ShowItemRef}/>
				<SpeedDial
					FabProps={{size: 'small', color: 'default'}}
					ariaLabel='More actions'
					className={'classes.speedDial'}
					icon={<SpeedDialIcon/>}
					onClose={this.handleClose}
					onOpen={this.handleOpen}
					open={this.state.open}
					direction={'down'}
				>
					{actions.map((action) => (
						<SpeedDialAction
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							onClick={this.handleClose}
						/>
					))}
				</SpeedDial>
			</>
		);
	}
}
export default withSnackbar(SpeedDials)
