import React, { useState, useEffect, useRef } from "react";
import Table from "../shared/tables/material_table";
import { useSnackbar } from "notistack";
import Backdrop from "../shared/backdrop";
import { getUsers, deleteUser } from "./api";
import { userColumns, userActions } from "./tableConfig";
import UserFrom from "./user_form"
const CoreUsers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [working, setWorking] = useState(false);
  const [users, setUsers] = useState([]);
  const [userRolesList, setUserRolesList] = useState([]);
  const tableRef = useRef(null);
  const userFormRef = useRef(null);

  const reloadUsers = async () => {
    setWorking(true);
    try {
      const data = await getUsers();
      setUsers(data.users || []);
      setUserRolesList(data.user_roles_list || []);
    } catch (error) {
      enqueueSnackbar("Failed to load users.", { variant: "error" });
    } finally {
      setWorking(false);
    }
  };

  const handleDelete = async (userId, userName) => {
    if (!window.confirm(`Are you sure you want to remove ${userName} from core users?`)) {
      return;
    }
    setWorking(true);
    try {
      await deleteUser(userId);
      enqueueSnackbar("Successfully deleted!", { variant: "success" });
      reloadUsers();
    } catch (error) {
      enqueueSnackbar(error.message || "Error occurred while deleting.", { variant: "error" });
    } finally {
      setWorking(false);
    }
  };

  useEffect(() => {
    reloadUsers();
  }, []);

  return (
    <div className="bg-white rounded">
      <Backdrop open={working} />
      <UserFrom ref={userFormRef} reloadData={reloadUsers}/>
      <Table
        title="System Users"
        options={{
          toolbar: true,
          filtering: false,
          sorting: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
        }}
        columns={userColumns}
        data={users}
        actions={userActions({ handleDelete, reloadUsers, userFormRef, userRolesList })}
        tableRef={tableRef}
      />
    </div>
  );
};

export default CoreUsers;
