import React from "react";
import Table from "../shared/tables/material_table";
import { withSnackbar } from "notistack";
import pureIcons from '../shared/pure_icons';
import Modal from "../shared/modal/material_dialog";
import {Typography, MenuItem, Select} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

class ShowLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      muiTableKey: 0,
      actions: [],
      statuses: []
    };
    this._tableRef = React.createRef();
    this._showPayloadRef = React.createRef();
  }

  reloadData = () => { this._tableRef.current.onQueryChange();};

  render() {
    const columns = [
      {title: "Search_key", field: "search_key", sorting: true},
      {title: "SKU", field: "sku", sorting: false, cellStyle: { width: '30%' }},
      { title: "Action", field: "action", editable: "never", sorting: false,
        filterComponent: ({ columnDef, onFilterChanged }) => <Select
          name={columnDef.tableData.id}
          value={columnDef.tableData.action}
          onChange={(e) => {
            onFilterChanged(columnDef.tableData.id, e.target.value);
          }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {this.state.actions.map(v =>
            <MenuItem key={v} value={v}>{v}</MenuItem>
          )}
        </Select>
      },
      { title: "Status Code", field: "response_status", sorting: false,
        filterComponent: ({ columnDef, onFilterChanged }) => <Select
          name={columnDef.tableData.id}
          value={columnDef.tableData.response_status}
          onChange={(e) => {
            onFilterChanged(columnDef.tableData.id, e.target.value);
          }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {this.state.statuses.map(v =>
            <MenuItem key={v} value={v}>{v}</MenuItem>
          )}
        </Select>
      },
      { title: "Date", field: "created_at", filtering: false}
    ];

    return (
      <div className="bg-white rounded">
        <ShowPayload ref={this._showPayloadRef}/>
        <Table
          title={this.props.title}
          key={this.state.muiTableKey}
          options={{
            search: false,
            actionsColumnIndex: -1,
            focus: true,
          }}
          localization={{
            toolbar: { searchPlaceholder: "Search key" },
          }}
          columns={columns}
          tableRef={this._tableRef}
          actions={[
            {icon: () => <pureIcons.BiShow />,
						 position: 'row',
						 onClick: (event, rowData) => {this._showPayloadRef.current.handleOpen(rowData)}
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              var filters;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }
              let url = `/logs/${this.props.page}.json?&`;
              url += "per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.filters.length > 0) {
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.records,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  if (this.state.actions.length === 0) {
                    this.setState({
                      actions: result.actions, statuses: result.statuses
                    });
                  }
                });
            })
          }
        />
      </div>
    );
  }
}
export default withSnackbar(ShowLogs);

class ShowPayload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      payload: {},
      response: {},
      title: '',
      url: ''
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = (data) => {
    this.setState({ open: true, payload: data.payload, response: data.response, title: data.title, url: data.url});
  };

  render() {
    const dataEntries = Array.isArray(this.state.payload)
      ? this.state.payload
      : Object.entries(this.state.payload);
    const dataEntries2 = Array.isArray(this.state.response)
      ? this.state.response
      : Object.entries(this.state.response);

      return (
        <Modal
          isOpen={this.state.open}
          title={'Search key ' + this.state.title}
          handleClose={this.handleClose}
          minHeight="40vh"
          maxHeight="80%"
          maxWidth="md"
          actions={''}
        >
          <Typography variant="body1" component="div">
            {this.state.url}
          </Typography>
          <Typography variant="body2" component="div">
            <h3>Request</h3>
            {dataEntries.slice(0, 3).map(([key, value]) => (
              <div key={key}>
                <strong>{key}</strong>: {String(JSON.stringify(value))}
              </div>
            ))}
            <hr />
            <h3>Response</h3>
            {dataEntries2.slice(0, 3).map(([key, value]) => (
              <div key={key}>
                <strong>{key}</strong>: {String(JSON.stringify(value))}
              </div>
            ))}
          </Typography>
        </Modal>
      );
  }
}
