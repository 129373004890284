import React from "react";
import pureIcons from "../../shared/pure_icons";
import { FormControl, Badge, TextField, Checkbox, Select, MenuItem, FormControlLabel, Radio } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Barcode from "../../order_items/_barcode";
import { saveFilterSelection } from "../../shared/filter_selection";
import { handleLockChange } from "./api";

export const columnsConfig = (component) => [
  {
    title: "",
    field: "image",
    sorting: false,
    filtering: false,
    render: (row) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60px",
        }}
      >
        <Badge
          badgeContent="DG"
          invisible={!row.dg}
          overlap="circular"
          color="error"
        >
          <img
            style={{ height: "60px", width: "60px", objectFit: "contain" }}
            alt={row.name}
            src={row.image}
          ></img>
        </Badge>
      </div>
    ),
  },
  {
    title: "Product Name",
    field: "name_short",
    sorting: false,
    filtering: false,
    cellStyle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 300,
    },

  },
  {
    title: "QTY",
    field: "qty",
    sorting: false,
    filtering: false,
  },
  {
    title: "Note",
    field: "note",
    filtering: false,
    sorting: false,
    cellStyle: { whiteSpace: "normal", wordWrap: "break-word", width: "15%" },
  },
  {
    title: "Code",
    field: "redeem_code",
    sorting: false,
    filtering: false,
    hidden: true,
  },
  {
    title: "SKU",
    field: "sku",
    sorting: false,
    filtering: false,
    hidden: true,
    cellStyle: {textOverflow: "ellipsis",whiteSpace: "nowrap",overflow: "hidden",maxWidth: 200},
  },
  {
    title: "Shipping Method",
    field: "shipping_method",
    sorting: false,
    filterComponent: () => (
      <FormControl component="fieldset" style={{ width: "90%" }}>
        <Select
          value={component.shippingOption.current}
          onChange={(e) => {
            component.handleRoleFilterChange(e.target.value),
              (component.shippingOption.current = e.target.value);
            saveFilterSelection("hub-selected-filter", e.target.value);
          }}
        >
          {component.state.shippingMethods?.map((item) => {
            return (
              <MenuItem value={item} style={{ padding: "0rem 1rem" }}>
                <FormControlLabel
                  value={item}
                  control={
                    <Radio checked={component.shippingOption.current == item} />
                  }
                  label={item}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    ),
    render: (rowData) => <span>{rowData?.shipping_method}</span>,
  },
  {
    title: "Processed By",
    field: "who_did_it",
    sorting: false,
    filterComponent: ({ columnDef, onFilterChanged }) => (
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={component.state.hubOperators}
        disableCloseOnSelect
        limitTags={1}
        onChange={(e, newValue) => {
          onFilterChanged(columnDef.tableData.id, newValue);
        }}
        getOptionLabel={(option) => option}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={<pureIcons.CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<pureIcons.CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="standard" />
        )}
      />
    ),
  },
  {
    title: "Tracking",
    field: "tracking",
    sorting: false,
    filtering: false,

    cellStyle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 300,
    },
  },
  {
    title: "Received",
    field: "created_at",
    filtering: false,

  },
];

export const tableActionsConfig = (component, source) => [
  {
    icon: pureIcons.FiRefreshCw,
    onClick: () => {component.reloadData()},
    isFreeAction: true,
    tooltip: "Refresh",
  },
  (rowData) => ({
    icon: () => <Barcode code={rowData.redeem_code} />,
    tooltip: <span>Barcode</span>,
    position: "row",
  }),
  (rowData) => ({
    tooltip: "Move to Shipment",
    icon: pureIcons.FiPackage,
    disabled: rowData.locked,
    onClick: (event, rowData) => component._stockFormRef.current.handleOpen(rowData),
    position: "row",
  }),
  {
    icon: pureIcons.ImInfo,
    tooltip: <span>Show</span>,
    onClick: (event, rowData) => component._showItemRef.current.handleOpen(rowData.order_item_id, source),
    position: "row",
  },
  (rowData) => ({
    icon: rowData.locked
      ? pureIcons.LockIcon
      : pureIcons.LockOpenIcon,
    tooltip: rowData.locked ? <span>Unlock</span> : <span>Lock</span>,
    onClick: (event, rowData) => {
      window.confirm(
        `Are you sure you want to ${
          rowData.locked ? "unlock" : "lock"
        } ${rowData.name} ?`
      ) && handleLockChange(rowData, source, component);
    },
    position: "row",
  }),
  {
    icon: pureIcons.BsChatDots,
    tooltip: <span>Notes</span>,
    onClick: (event, rowData) =>
      component._noteFormRef.current.handleOpen(rowData.order_item_stage_id, source),
    position: "row",
  }
];
