import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Modal from "../shared/modal/material_dialog";
import { useSnackbar } from "notistack";
import Backdrop from "../shared/backdrop";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Badge,
  CardMedia,
  CardContent,
  Typography,
  Link,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import NoteCard from "../notes/_note_card";
import StageTabs from "./_item_stages_tabs";
// import ShowOrder from "../../orders/_show"
import ShowOrder from "../orders/_show";
import Alert from "@material-ui/lab/Alert";
import CopyTextComponent from "../shared/copy_clipboard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 10,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 250,
    height: 250,
    objectFit: "contain",
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const ShowItems = forwardRef(({ reloadData }: any, ref: any) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [working, setWorking] = useState<boolean>(false);
  const [source, setSource] = useState<any>();
  const [item, setItem] = useState<any>();
  const _ShowOrderRef = useRef<any>(null);

  const getItem = (item_id: any, source: any) => {
    const url = `/order_items/show_item.json?id=${item_id}&source=${source}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        setItem(result);
        setWorking(false);
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
        setWorking(false);
      });
  };

  useImperativeHandle(ref, () => ({
    handleOpen(item_id, source) {
      setOpen(true);
      setWorking(true);
      setSource(source);
      getItem(item_id, source);
    },
  }));

  const reloadsData = () => {
    getItem(item?.order_item_id, source);
    !!reloadData && reloadData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const orderTitle = (
    <>
      <Button
        style={{ fontSize: "inherit", color: "#f6b67f", fontWeight: "bold" }}
        onClick={() =>
          _ShowOrderRef.current.handleOpen(item?.order_number, source)
        }
      >
        {item?.order_number}
      </Button>
      {`(${item?.brand}) `}
      <IconButton
        onClick={() => navigator.clipboard.writeText(item?.order_number)}
        aria-label="copy"
      >
        <CopyTextComponent textValue={item?.order_number} />
      </IconButton>
    </>
  );
  return (
    <Modal
      disableBackdropClick={false}
      isOpen={open}
      title={orderTitle}
      handleClose={handleClose}
      minHeight="85%"
      maxWidth="xl"
      maxHeight="85%"
      actions={<></>}
    >
      <>
        <Card className={classes.root}>
          <Backdrop open={working} />
          <ShowOrder ref={_ShowOrderRef} />
          <CardMedia className={classes.cover}>
            <Badge
              badgeContent="DG"
              invisible={!item?.dg}
              overlap="circular"
              color="error"
            >
              <img
                src={item?.image}
                alt={item?.name}
                style={{
                  height: "250px",
                  width: "200px",
                  objectFit: "contain",
                }}
              />
            </Badge>
          </CardMedia>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5">
                <Link href={item?.url} target="_blank">
                  {item?.name}
                </Link>
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  {item?.prohibited && (
                    <Alert variant="filled" severity="error">
                      This item? could be Prohibited — please check it out!
                    </Alert>
                  )}
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Price: <b>${item?.price}</b>
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Quantity: <b>{item?.qty}</b>
                      </Typography>
                      {item?.shipping_to_hub !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Local Shipping: <b>${item?.shipping_to_hub}</b>
                        </Typography>
                      )}
                      <Typography variant="subtitle1" color="textSecondary">
                        Customs Fees: <b>${item?.customs_fees}</b>
                      </Typography>
                      {item?.serial_number && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Serial number:{" "}
                          <b>{item?.serial_number}</b>
                        </Typography>
                      )}
                      {item?.expired_date && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Expired date:{" "}
                          <b>{item?.expired_date}</b>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {item?.adjustment !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Adjustment fee: <b>${item?.adjustment}</b>
                        </Typography>
                      )}
                      {item?.insurance !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Insurance: <b>${item?.insurance}</b>
                        </Typography>
                      )}
                      {item?.dangerous_fee !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          DG Fees: <b>${item?.dangerous_fee}</b>
                        </Typography>
                      )}
                      <Typography variant="subtitle1" color="textSecondary">
                        Shipping Fees:{" "}
                        <b>${item?.shipped_to_final_destination}</b>
                      </Typography>
                      {item?.vendor_shipping && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Vendor shipping: <b>${item?.vendor_shipping}</b>
                        </Typography>
                      )}
                      {item?.profit !== 0 && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Shipping Profit: <b>{item?.profit}</b>
                        </Typography>
                      )}
                      <Typography variant="subtitle1" color="textSecondary">
                        Predict: <b>{item?.predict}</b>
                      </Typography>
                      {item?.category_weight && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Category weight: <b>{item?.category_weight}</b>
                        </Typography>
                      )}
                      {item?.review_weight && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Review weight: <b>{item?.review_weight}</b>
                        </Typography>
                      )}
                      {item?.review_dimensions && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Review dimensions: <b>{item?.review_dimensions}</b>
                        </Typography>
                      )}
                      {item?.review_weight_liquid && (
                        <Typography variant="subtitle1" color="textSecondary">
                          Review weight liquid:{" "}
                          <b>{item?.review_weight_liquid}</b>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Typography variant="subtitle1" color="textSecondary">
                    Item ID: <b>{item?.order_item_id}</b>
                  </Typography>
                  {item?.customizations?.map((c) => {
                    if (c.value !== null && c.value !== "")
                      return (
                        <Typography
                          variant="subtitle1"
                          color="textSecondary"
                          key={c.name}
                        >
                          {c.name}: <b>{c.value}</b>
                        </Typography>
                      );
                  })}
                </Grid>
                <Grid item xs={6}>
                  <div>
                    {item?.notes && item?.notes.length > 0 && (
                      <NoteCard notes={item?.notes} source={source} />
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </Card>
        <div className={classes.root}>
          <StageTabs item={item} reloadData={reloadsData} source={source} />
        </div>
      </>
    </Modal>
  );
});

export default ShowItems;
