import React from "react";
import Table from "../../shared/tables/new_core_material_table";
import { withSnackbar } from "notistack";
import { fetchData } from "./api";
import { getFilterSelection } from "../../shared/filter_selection";
import StockForm from "./_stock_form";
import NoteForm from "../../notes/_note_form";
import ShowItem from "../../order_items/show";
import { columnsConfig, tableActionsConfig } from "./tableConfig";

class Stocked extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      muiTableKey: 0,
      total: null,
      shippingMethods: [],
      hubOperators: []
    };
    this.shippingOption = React.createRef("All");
    this.shippingOption.current = getFilterSelection("hub-selected-filter") || props.initialValue || "All";
    this._tableRef = React.createRef();
    this._stockFormRef = React.createRef();
    this._noteFormRef = React.createRef();
    this._showItemRef = React.createRef();
  }

  reloadData = () => {
    this._tableRef.current.onQueryChange();
  };

  handleRoleFilterChange = (val) => {
    this.setState({ setSelectedRoles: val });
    this.shippingOption.current = val;
    this.reloadData();
  };

  render() {
    const {classes} = this.props;
    const { muiTableKey} = this.state;
    const tableColumns = columnsConfig(this);
    const source = localStorage.getItem("source");
    const destination = localStorage.getItem("destination");

    return (
      <div className="bg-white rounded">
        <StockForm ref={this._stockFormRef} source={source} destination={destination} callBackStageQty={this.reloadData} stock={false} enqueueSnackbar={withSnackbar} classes={classes}/>
        <NoteForm ref={this._noteFormRef} newNoteChild={this.reloadData}/>
        <ShowItem ref={this._showItemRef} />
        <Table
          title="Items in Stock"
          key={muiTableKey}
          columns={tableColumns}
          tableRef={this._tableRef}
          actions={tableActionsConfig(this, source)}
          data={(query) => fetchData(query, this.shippingOption.current , source, destination, this)}
        />
      </div>
    );
  }
}

export default withSnackbar(Stocked);
