import React from 'react';
import ShowProductCard from './show/_product_card';
import ProductCardSkeleton from "../shared/skeletons/product_card_skeleton";

export default class ShowOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			order_number: '',
			notes_count: 0,
			order: { vendors: [] },
			working: true,
		};
	}

	componentDidMount() {
		this.setState({ working: true });
		const url = `/orders/show_order.json?order_number=${this.props.order_number}&source=${this.props.source}`
		fetch(url)
			.then(response => response.json())
			.then(result => { this.setState({ order: result, working: false }); this.props.callBack(result.new_customer) })
	}

	render() {
		const body = this.state.order.vendors.map(vendor =>
			<div className="card p-1">
				<div className="card-header" style={{ 'background-color': 'rgba(109, 199, 178, 0.7)' }}>
					{vendor.name}
				</div>
				{vendor.products.map(product => <ShowProductCard product={product} source={this.props.source}/>)}
			</div>
		)

		return (
			<>{this.state.working ? <ProductCardSkeleton /> : body}</>
		);
	}
}
